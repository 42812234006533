import { useEffect, useState } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import axios from 'axios';
import { Text, H4, colors, Button, H3 } from '@digitalportal-ui/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TagManager from 'react-gtm-module';
import routes from '../../../enums/routes';
import { updateHelperIsProgressShown, updateModalInfo } from '../../../redux/slices/helpers';
import { TStore } from '../../../redux/store';
import SummaryCard from '../../molecules/SummaryCard/SummaryCard';
import Header from '../../templates/Header/Header';
import {
  CalendarIconStyled,
  EmailIconStyled,
  HeroWrapper,
  StyledTitleTypography,
  HeroTextWrapper,
  CardWrapper,
  PageWrapper,
  StyledParagraphTypography,
  QuoteTextWrapper,
  DateTextWrapper,
  CalendarTextWrapper,
  StyledReminderTypography,
  StyledMain,
  PostalTextWrapper,
} from './styles';
import FrequentlyAskedQuestions from '../../organisms/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import MemberCards from './MemberCards/MemberCards';
import HospitalNetworkSelection from './HospitalNetworkSelection/HospitalNetworkSelection';
import ImportantInformation from './ImportantInformation/ImportantInformation';
import DownloadCards from './DownloadCards/DownloadCards';
import EverydayHealthcareBenefits from '../entitlements/EverydayHealthcareBenefits';
import { FooterWithStaticLinks } from '../../templates/Footer/Footer';
import {
  updateQuoteAnnualPremiums,
  updateMember,
  Member,
  updateQuoteMonthlyPremiums,
} from '../../../redux/slices/quote';
import { getUpdatedEntitlementsWithPrices } from '../entitlements/entitlements';
import { GAQuoteData, EcommerceData } from '../../../lib/tagManager/commonFunctions';
import CampaignCard from '../../molecules/CampaignCard/CampaignCard';
import MarksIncentive from '../../../assets/svg/MarksIncentive.svg?react';
import SpecialistSelection from './SpecialistSelection/SpecialistSelection';
import { getPlans, PriceResponse } from '../../../lib/utils/services/pricingService';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import { ConfirmationModal } from './Modals/Confirmation/Confirmation';
import { InfoModal } from './Modals/Info/Info';
import PostalErrorModal from './Modals/PostalError/PostalError';
import { PreExisitingConditionsModal } from './Modals/PreExisitingConditions/PreExisitingConditions';
import HandleError from '../../../lib/utils/handleError';

const initialCardValues = {
  quoteNumber: '123456789',
  expirationDate: '9 May 2021',
  description:
    'Your price is based on the information you provided. Any change to these information will result in the quote to be outdated.',
  monthlyPremium: '55.20',
  months: '12',
  annualPremium: '662.40',
  insurance: '582.91',
  taxPercentage: '12',
  tax: '79.49',
  discount: '0',
};

export default function QuoteSummary(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const campaign = useSelector((state: TStore) => state.helpersState.helpers.campaign);
  const locationState = useLocation().state as { usedRouter: boolean };
  const confirmationModalTitle = 'Receive a postal copy of your quote';
  const infoModalTitle = 'Great, keep an eye out for your postal quote';
  const postalAlreadyExistsModalTitle = 'We have an existing request';
  const postalErrorModalTitle = 'Receive a postal copy of your quote';
  const preExistingConditionsTitle = 'Please confirm you understand we will not cover pre-existing conditions';
  dayjs.extend(utc);

  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({
    isPostQuoteModal: false,
    isPostQuoteConfirmationModal: false,
    errorModalOpen: false,
    isPostAlreadyExistsModal: false,
    showPreExistingConditionsModal: false,
  });

  const initialQuoteValues = useSelector((state: TStore) => state.quoteState.quote);
  const initialMembersValues = initialQuoteValues?.members.find((member) => member.policyHolder);
  const policyHolderName = initialMembersValues?.preferredName
    ? initialMembersValues?.preferredName
    : initialMembersValues?.firstname;
  const { navigateOptions, initialEntitlements, salesPhoneNumber } = useSelector(
    (state: TStore) => state.helpersState.helpers,
  );
  const [switchValue, setSwitchValue] = useState(initialQuoteValues?.paymentFrequency === 'Annual');

  const { termsConditions, incentive } = useSelector((state: TStore) => state.helpersState.helpers.campaign);

  const unhappyPathOptions: NavigateOptions = {
    state: {
      usedRouter: true,
      title: 'We are unable to progress your quote at this time',
      body: 'Please try again or call us',
      route: routes.quoteSummary,
      fromRoute: routes.quoteSummary,
    },
  };

  const updateAlternativePayFrequencyPremium = (priceResponse: PriceResponse) => {
    if (initialQuoteValues.paymentFrequency === 'Monthly') {
      dispatch(
        updateQuoteAnnualPremiums({
          price: priceResponse.annualPremium,
          tax: priceResponse.annualTax,
          totalContractPrice: priceResponse.totalContractPrice,
        }),
      );
    } else {
      dispatch(
        updateQuoteMonthlyPremiums({
          price: priceResponse.monthlyPremium,
          tax: priceResponse.monthlyTax,
          totalContractPrice: priceResponse.totalContractPrice,
        }),
      );
    }

    priceResponse.members.forEach((member: Member, index: number) => {
      const validEntitlements = getUpdatedEntitlementsWithPrices(
        initialEntitlements,
        member,
        initialQuoteValues.members[index].entitlements,
        initialQuoteValues.paymentFrequency === 'Annual',
      );
      let updateMemberValues: Partial<Member>;

      if (initialQuoteValues.paymentFrequency === 'Monthly') {
        updateMemberValues = {
          annualPremium: member.annualPremium,
          entitlements: validEntitlements,
        } as Partial<Member>;
      } else {
        updateMemberValues = {
          monthlyPremium: member.monthlyPremium,
          entitlements: validEntitlements,
        } as Partial<Member>;
      }

      dispatch(
        updateMember({
          userEditIndex: index,
          values: updateMemberValues,
        }),
      );
    });
  };

  useEffect(() => {
    if (
      locationState?.usedRouter &&
      initialQuoteValues.quoteStatus !== 'Expired' &&
      initialQuoteValues.quoteNumber !== ''
    ) {
      dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
      setLoading(true);

      const membersBody = initialQuoteValues.members.map((member) => ({
        id: member.id,
        title: member.title,
        firstname: member.firstname,
        lastname: member.lastname,
        dateOfBirth: `${member.dob.year}-${member.dob.month}-${member.dob.day}`,
        excess: member.excess,
        entitlements: member.entitlements,
        postcode: member.postcode,
        city: member.city,
        addressLine1: member.addressLine1,
        addressLine2: member.addressLine2,
        policyHolder: member.policyHolder,
        majorHealthQuestions: member.majorHealthQuestions,
        consultation: member.consultation,
        preferredName: member.preferredName,
        ncdProtection: member.ncdProtection === 'Yes',
        email: member.email,
        phone: member.phone,
      }));

      getPlans(
        {
          members: membersBody,
          coverStartDate: initialQuoteValues.coverStartDate,
          hospitalNetwork: initialQuoteValues.hospitalNetwork,
          leadId: initialQuoteValues.members.find((member) => member.policyHolder)?.leadId ?? '',
          paymentFrequency: initialQuoteValues.paymentFrequency === 'Monthly' ? 'Annual' : 'Monthly',
          opportunityId: initialQuoteValues.opportunityId,
          quoteKey: initialQuoteValues.quoteKey,
          quoteNumber: initialQuoteValues.quoteNumber,
          draftQuoteLastUpdated: dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
        },
        true,
      )
        .then((result) => {
          updateAlternativePayFrequencyPremium(result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (!axios.isCancel(error)) {
            HandleError(error, dispatch);
            history(routes.unhappyPath, unhappyPathOptions);
          }
        });
    }
  }, [initialQuoteValues.quoteNumber]);

  const onSwitchChange = () => {
    setSwitchValue(!switchValue);
  };

  const proceedHandler = () => {
    if (initialQuoteValues.quoteStatus !== 'Expired') {
      // here open pop up and move tag manager event into pop up submitter
      setModals({ ...modals, showPreExistingConditionsModal: true });
    } else if (initialQuoteValues.quoteStatus === 'Expired') {
      history(routes.coverStartDate, navigateOptions);
    }
  };

  const preExistingConfirmationHandler = () => {
    const price =
      initialQuoteValues.paymentFrequency === 'Monthly'
        ? initialQuoteValues.monthlyTotalContractPrice
        : initialQuoteValues.annualTotalContractPrice;
    const quoteEventData = GAQuoteData(initialQuoteValues, 'add_to_cart');
    const ecommerceEventData = EcommerceData(initialQuoteValues, price);
    quoteEventData.ecommerce = ecommerceEventData;
    const tagManagerArgs = {
      dataLayer: quoteEventData,
    };
    TagManager.dataLayer(tagManagerArgs);
    history(routes.checkoutPolicyDocs, navigateOptions);
  };

  const postalQuoteHandler = () => {
    setModals({ ...modals, isPostQuoteModal: true });
    dispatch(updateModalInfo({ isModalOpen: true, modalTitle: confirmationModalTitle }));
  };

  return (
    <>
      <Header />
      <Box
        bgcolor={initialQuoteValues.quoteStatus === 'Expired' ? colors.darkGrey : colors.oceanBlue}
        width="100%"
        display="flex"
        justifyContent="center"
      >
        <HeroWrapper>
          <HeroTextWrapper>
            <QuoteTextWrapper data-testid="textDivider">
              <H4 bold color="inherit" data-testid="label-quote-number">
                Quote number:
              </H4>
              <H4 color="inherit" data-testid="number-quote-number">
                {` #${initialQuoteValues.quoteNumber || initialCardValues.quoteNumber}`}
              </H4>
            </QuoteTextWrapper>
            <DateTextWrapper data-testid="textDivider" fontWeight="bold">
              <Text color="inherit" data-testid="expirytDate">
                {`Valid until: ${dayjs(initialQuoteValues.expirationDate).format('DD MMMM YYYY')}`}
              </Text>
            </DateTextWrapper>
            <StyledTitleTypography
              className="axa-mask"
              sx={{ wordBreak: 'break-word' }}
              data-testid="pageHeading"
              variant="h1"
            >
              {initialQuoteValues.quoteStatus !== 'Expired'
                ? `Here’s your quote summary, ${policyHolderName}.`
                : `Hi ${policyHolderName}, your quote has expired.`}
            </StyledTitleTypography>
            {initialQuoteValues.quoteStatus !== 'Expired' && (
              <StyledReminderTypography bold>We’ve sent a copy of this quote by email</StyledReminderTypography>
            )}
            <PostalTextWrapper
              role="button"
              aria-label="Please send a copy by post"
              onClick={postalQuoteHandler}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postalQuoteHandler();
                }
              }}
              tabIndex={0}
              data-testid="textDivider"
            >
              <EmailIconStyled />
              <H3 data-testid="postal-modal-link" bold>
                Please send a copy by post
              </H3>
            </PostalTextWrapper>
            <StyledParagraphTypography
              style={{ lineHeight: 'inherit', marginBottom: '8px' }}
              variant="h4"
              fontWeight="regular"
            >
              {initialQuoteValues.quoteStatus !== 'Expired'
                ? 'You can view your cover and information below.'
                : 'To reactivate your quotation you will need to update your start date.'}
            </StyledParagraphTypography>
            <StyledParagraphTypography style={{ lineHeight: 'inherit', marginBottom: '1px' }} variant="h4" fontWeight="regular">
              {initialQuoteValues.quoteStatus !== 'Expired'
                ? 'To change anything select ‘View cover‘.'
                : ''}
            </StyledParagraphTypography>
            <StyledParagraphTypography style={{ lineHeight: 'inherit' }} variant="h4" fontWeight="regular">
              {initialQuoteValues.quoteStatus !== 'Expired'
                ? 'Once you’re happy, check everything meets your needs before you buy.'
                : ''}
            </StyledParagraphTypography>
            <DateTextWrapper data-testid="textDivider" fontWeight="bold">
              <H4 bold>Your membership will start on</H4>
              <H4 data-testid="coverStartDate">{dayjs(initialQuoteValues.coverStartDate).format('DD MMMM YYYY')}</H4>
            </DateTextWrapper>
            {initialQuoteValues.quoteStatus !== 'Purchased' && (
              <CalendarTextWrapper
                role="link"
                aria-label="Change the start date"
                onClick={() => history(routes.coverStartDate, navigateOptions)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    history(routes.coverStartDate, navigateOptions);
                  }
                }}
                data-testid="change-the-start-date"
                tabIndex={0}
              >
                <CalendarIconStyled />
                <Text semibold>Change the start date</Text>
              </CalendarTextWrapper>
            )}
          </HeroTextWrapper>
          <CardWrapper>
            <SummaryCard
              onSwitchChange={onSwitchChange}
              quote={{
                monthlyPremium: initialQuoteValues.monthlyPremium,
                annualPremium: initialQuoteValues.annualPremium,
                monthlyTotalContractPrice: initialQuoteValues.monthlyTotalContractPrice,
                annualTotalContractPrice: initialQuoteValues.annualTotalContractPrice,
                monthlyTaxPremium: initialQuoteValues.monthlyTaxPremium,
                annualTaxPremium: initialQuoteValues.annualTaxPremium,
                switchValues: [
                  { title: 'Monthly', subtitle: '', checked: !switchValue },
                  { title: 'Annually', subtitle: '(Save 5%)', checked: switchValue },
                ],
                quoteStatus: initialQuoteValues.quoteStatus,
              }}
              loading={loading && switchValue !== (initialQuoteValues?.paymentFrequency === 'Annual')}
              submitButtonHandler={proceedHandler}
              incentive={incentive}
            />
          </CardWrapper>
        </HeroWrapper>
      </Box>
      <StyledMain>
        <PageWrapper>
          {campaign.marketingRef === 'm_and_s_jan_2025' && (
            <CampaignCard
              header="A little extra something for you"
              subHeader="Purchase our AXA Health Plan by 28 February 2025 and receive a £100 M&S gift card"
              bodyText="Once you take out a private healthcare plan with us, the lead member will be sent an email to redeem the gift card within 28 days of their annual payment or within 28 days of their second monthly payment."
              termsAndConditions={termsConditions}
              image={MarksIncentive}
            />
          )}
          <MemberCards paymentFrequency={switchValue} />
          <SpecialistSelection />
          <HospitalNetworkSelection />
          <EverydayHealthcareBenefits />
          <ImportantInformation />
          <DownloadCards />
          <FrequentlyAskedQuestions />
          {initialQuoteValues.quoteStatus !== 'Purchased' && (
            <Button
              disabled={loading}
              data-testid="summarySubmitBtn-bottom"
              color="secondary"
              size="large"
              onClick={proceedHandler}
              style={{ whiteSpace: 'nowrap' }}
            >
              {initialQuoteValues.quoteStatus === 'Expired' ? 'Change start date' : "I'm happy to go ahead and pay"}
            </Button>
          )}
          <ConfirmationModal
            open={modals.isPostQuoteModal}
            modalTitle={confirmationModalTitle}
            onClose={() => {
              setModals({ ...modals, isPostQuoteModal: false, isPostQuoteConfirmationModal: false });
              dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
            }}
            onSave={(success: boolean, taskAlreadyExists: boolean) => {
              let modalTitle = '';
              if (success) {
                modalTitle = infoModalTitle;
                setModals({
                  ...modals,
                  isPostQuoteModal: false,
                  isPostQuoteConfirmationModal: true,
                });
              } else if (taskAlreadyExists) {
                modalTitle = postalAlreadyExistsModalTitle;
                setModals({
                  ...modals,
                  isPostQuoteModal: false,
                  isPostAlreadyExistsModal: true,
                });
              } else {
                modalTitle = postalErrorModalTitle;
                setModals({
                  ...modals,
                  isPostQuoteModal: false,
                  errorModalOpen: true,
                });
              }
              dispatch(updateModalInfo({ isModalOpen: true, modalTitle }));
            }}
            onError={() => {
              setModals({
                ...modals,
                isPostQuoteModal: false,
                isPostQuoteConfirmationModal: false,
                errorModalOpen: true,
              });
              dispatch(updateModalInfo({ isModalOpen: true, modalTitle: postalErrorModalTitle }));
            }}
            modalData={{
              ...(initialMembersValues ?? ({} as Member)),
              quoteNumber: initialQuoteValues.quoteNumber,
              quoteKey: initialQuoteValues.quoteKey,
            }}
          />
          <InfoModal
            open={modals.isPostQuoteConfirmationModal}
            modalTitle={infoModalTitle}
            text="Confirmation message of postal quote received"
            onSave={() => setModals({ ...modals, isPostQuoteConfirmationModal: false })}
            onClose={() => setModals({ ...modals, isPostQuoteConfirmationModal: false })}
          />
          <InfoModal
            open={modals.isPostAlreadyExistsModal}
            modalTitle={postalAlreadyExistsModalTitle}
            text="Your quote is on the way"
            onSave={() => setModals({ ...modals, isPostAlreadyExistsModal: false })}
            onClose={() => setModals({ ...modals, isPostAlreadyExistsModal: false })}
          />
          <PostalErrorModal
            open={modals.errorModalOpen}
            modalTitle={postalErrorModalTitle}
            quoteNumber={initialQuoteValues.quoteNumber || initialCardValues.quoteNumber}
            onClose={() => setModals({ ...modals, errorModalOpen: false })}
            phoneNumber={salesPhoneNumber}
          />
          <PreExisitingConditionsModal
            open={modals.showPreExistingConditionsModal}
            modalTitle={preExistingConditionsTitle}
            onClose={() => setModals({ ...modals, showPreExistingConditionsModal: false })}
            onSave={preExistingConfirmationHandler}
          />
        </PageWrapper>
      </StyledMain>
      <FooterWithStaticLinks />
    </>
  );
}
