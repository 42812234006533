import { useDispatch } from 'react-redux';
import { Box, RadioGroup } from '@mui/material';
import React, { Fragment } from 'react';
import { H3, Text, colors, RadioVariants } from '@digitalportal-ui/core';
import { CardType, PaymentMethodQuestionProps } from '../types';
import Amex from '../../../../assets/svg/amex.svg?react';
import Visa from '../../../../assets/svg/visa.svg?react';
import Mastercard from '../../../../assets/svg/mastercard.svg?react';
import DebitCards from '../../../../assets/svg/direct_debit.svg?react';
import { updatePaymentMethodType } from '../../../../redux/slices/policy/policy.slice';
import { CheckBoxLabel, ConsentBox, ConsentMessage, InnerRadioContainer } from '../styles';
import { Checkbox } from '../../../atoms';
import ErrorMessageBox from '../../../molecules/ErrorMessageBox/errorMessageBox';
import QuestionHeader from '../../../atoms/QuestionHeader/QuestionHeader';
import FormikRadioBlock from '../../../molecules/FormikRadioBlock/FormikRadioBlock';
import LeadMemberModal from '../LeadMemberModal';

const initialPaymentDetails = {
  cardTypes: [
    {
      text: 'Credit/Debit card',
      value: 0,
      subtext: "We'll need your consent for us to save your card details to pay for your plan",
      svgCards: [Visa, Mastercard, Amex],
      selected: true,
    },
    {
      text: 'Direct Debit',
      value: 1,
      subtext: 'You need to be the lead member and the account holder to authorise payment',
      svgCards: [DebitCards],
      selected: false,
    },
  ],
};

const displayConsent = (isShown: boolean, errors?: string, touched?: boolean): React.JSX.Element | null =>
  isShown ? (
    <ConsentBox key={`${isShown}consent`}>
      <ConsentMessage>
        By choosing to pay this way, you agree to our storing your card details and using them to pay for your plan.
        We’ll do this until your card expires, or until you or we cancel your plan.
        <br />
        <br />
        If you want to change your payment details at any time, you can do this by calling us.
      </ConsentMessage>
      <Checkbox isErrored={touched && errors !== undefined} name="storingDetailsConsent" testId="storingDetailsConsent">
        <CheckBoxLabel>
          I confirm that I understand that AXA Health Limited will store my card details, and consent to this.
        </CheckBoxLabel>
      </Checkbox>
    </ConsentBox>
) : null;

function PaymentMethodQuestion({
  touched,
  value,
  errorClose,
  setErrorClose,
  error,
}: PaymentMethodQuestionProps): React.JSX.Element {
  const dispatch = useDispatch();
  return (
    <Box>
      <QuestionHeader key="payment-method-header" title="Which payment method would you like to use?" testId="payment-method-question" />
      <RadioGroup aria-label="radio-group-answers" sx={{ flexWrap: 'nowrap' }}>
        {initialPaymentDetails.cardTypes.map((details: CardType, index: number) => (
          <Fragment key={details.value}>
            <FormikRadioBlock
              key={`${details.value}-radio-block`}
              value={details.value}
              name="paymentMethod"
              variant={RadioVariants.rowWide}
              checkedColor={colors.oceanBlue}
              isErrored={touched && value === undefined}
              dataTestId={`radioButton-${details.text.replace(/\s+/g, '-')}`}
              onClick={() => dispatch(updatePaymentMethodType(details.value === 0 ? 'Card' : 'Direct Debit'))}
              ariaLabel={details.text}
            >
              <InnerRadioContainer key={`${details.value}-inner-radio-block`}>
                <Box key={`${details.value}-inner-radio-block-box`} sx={{ marginRight: { sm: '0', md: '32px' } }}>
                  <H3 key={details.text} bold>{details.text}</H3>
                  <Text key={details.subtext} variant="body1">{details.subtext}</Text>
                </Box>
                <Box key={`${details.value}-card-image-box`} sx={{ display: { sm: 'none', md: 'flex' } }}>
                  {details.svgCards.map((image, imageIndex) => React.createElement(image, { style: { marginRight: '4px' }, key: `image-${imageIndex.toString()}` }))}
                </Box>
              </InnerRadioContainer>
            </FormikRadioBlock>
            {displayConsent(
              index === 0 && value === details.value,
              error,
              touched,
            )}
          </Fragment>
        ))}
      </RadioGroup>
      <LeadMemberModal sx={{ marginTop: { md: '16px' } }} />
      {touched && value === undefined && !errorClose && (
        <Box sx={{ marginTop: { md: '16px' } }}>
          <ErrorMessageBox closeError={setErrorClose} />
        </Box>
      )}
    </Box>
  );
}

export default PaymentMethodQuestion;
