import * as React from 'react';
import { Box, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, colors, H5 } from '@digitalportal-ui/core';
import { Dispatch, SetStateAction } from 'react';
import AddMemberDetailsHeader from '../AddMemberDetailsHeader/AddMemberDetailsHeader';
import {
  AvatarGrid,
  DetailsBox,
  ContainerBox,
  CardContainerGrid,
  ButtonGrid,
  ExpandMoreButton,
  StatusTextWrapper,
  ShowHideBox,
  ShowMoreWrapper,
  CardContentWrapper,
} from './styles';
import { Member } from '../../../redux/slices/quote';

export type AddMemberCardDetailsProps = {
  policyHolder: boolean;
  memberDetailsProps: {
    id: string;
    firstname: string;
    lastname: string;
    dob: {
      day: string;
      month: string;
      year: string;
    };
    postcode: string;
    email: string;
    phone: string;
  };
  cardContentChildren?: React.ReactNode;
  addMemberCardActions?: React.ReactNode;
  expanded?: boolean;
  toggleExpanded?: (expanded: boolean) => void;
  statusText?: React.ReactNode;
  showMore?: boolean;
  showStatusText?: boolean;
  padding?: string;
  memberIndex?: number;
  ipidHandler?: (memberDetails : Partial<Member>, setLoading : Dispatch<SetStateAction<boolean>>) => void;
  loadingIpid?: boolean;
};

const AddMemberDetailsCard = ({
  memberDetailsProps,
  policyHolder,
  cardContentChildren,
  addMemberCardActions,
  expanded,
  showMore,
  showStatusText,
  toggleExpanded = () => null,
  statusText = '',
  padding = '',
  memberIndex,
  ipidHandler,
}: AddMemberCardDetailsProps): React.JSX.Element => {
  const getInitials = () => {
    const firstName = memberDetailsProps?.firstname.trim();
    const lastName = memberDetailsProps?.lastname.trim();
    return firstName.charAt(0) + lastName.charAt(0);
  };

  return (
    <ContainerBox data-testid="addMemberCardContainer">
      <CardContainerGrid container data-testid="addMemberGridContainer">
        <AvatarGrid item>
          <Avatar data-testid="addMemberAvatar">{getInitials()}</Avatar>
        </AvatarGrid>
        <DetailsBox item data-testid="addMemberCardDetails">
          <AddMemberDetailsHeader
            policyHolder={policyHolder}
            memberDetailsProps={memberDetailsProps}
            expanded={expanded}
            ipidHandler={ipidHandler}
          />
        </DetailsBox>
        {addMemberCardActions && (
          <ButtonGrid item data-testid="addMemberCardAction">
            {addMemberCardActions}
          </ButtonGrid>
        )}
        {((expanded === false && statusText) || (statusText && showStatusText)) && (
          <StatusTextWrapper data-testid="text-radioButtonSelectedValue" whiteSpace="nowrap">
            <Box fontWeight={700} fontSize="20px" lineHeight="20px">
              {statusText}
            </Box>
          </StatusTextWrapper>
        )}
        {expanded !== undefined && (
          <ExpandMoreButton
            onClick={() => {
              toggleExpanded(expanded);
            }}
            aria-expanded={expanded}
            $expanded={expanded}
            aria-label="Show More"
            data-testid={`button-expand-${memberIndex}`}
          >
            <ExpandMoreIcon />
          </ExpandMoreButton>
        )}
      </CardContainerGrid>
      {cardContentChildren && (
        <Collapse in={expanded} timeout="auto">
          <CardContentWrapper padding={padding}>{cardContentChildren}</CardContentWrapper>
        </Collapse>
      )}
      {expanded !== undefined && showMore && (
        <ShowHideBox
          onClick={() => {
            toggleExpanded(expanded);
          }}
          data-testid="content-expand"
        >
          <H5
            bold
            sx={{
              cursor: 'pointer',
              color: colors.oceanBlueDark,
            }}
          >
            {expanded ? 'Hide cover' : 'View cover'}
          </H5>
          <ShowMoreWrapper
            aria-expanded={expanded}
            $expanded={expanded}
            aria-label="Show More"
            data-testid="button-expand"
          >
            <ExpandMoreIcon />
          </ShowMoreWrapper>
        </ShowHideBox>
      )}
    </ContainerBox>
  );
};

export default AddMemberDetailsCard;
