import { useDispatch, useSelector } from 'react-redux';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import { Text, RadioVariants } from '@digitalportal-ui/core';
import { useState } from 'react';
import { TStore } from '../../../redux/store';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import {
  BulletPoint,
  MainWrapper,
  RadioBlockWrapper,
  RadioContainer,
  ContentWrapper,
  TitleWrapper,
  CheckboxContainer,
} from './styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import routes from '../../../enums/routes';
import { ExternalStyledLink } from '../../atoms/Link/Link';
import { Member } from '../../../redux/slices/quote';
import GenericCard from '../../molecules/GenericCard/GenericCard';
import CheckoutCheckbox from './CheckoutCheckbox';
import OnlineOrPostBlock from './OnlineOrPostBlock';
import { updateConsent } from '../../../lib/utils/services/quoteService';
import HandleError from '../../../lib/utils/handleError';
import Loader from '../../atoms/Loader/Loader';
import {
  updateMarketingConsents,
  updateContactPreferences,
  updateCommunicationNeeds,
  updateCommunicationPreference,
} from '../../../redux/slices/policy/policy.slice';
import FormikRadioBlock from '../../molecules/FormikRadioBlock/FormikRadioBlock';

export default function CheckoutPolicyDocs(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initialContacts =
    useSelector((state: TStore) => state.quoteState.quote.members.find((member) => member.policyHolder)) ??
    ({} as Member);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const { quoteNumber, quoteKey } = useSelector((state: TStore) => state.quoteState.quote);
  const { marketingConsents, contactPreferences, communicationNeeds, communicationPreference } = useSelector(
    (state: TStore) => state.policyState.policy,
  );

  const initialValues = {
    contactPreferences,
    marketingConsents,
    communicationNeeds,
    communicationPreference,
  };

  const unhappyPathOptions: NavigateOptions = {
    state: {
      usedRouter: true,
      title: 'We are unable to progress your quote at this time',
      body: 'Please try again or call us',
      route: routes.checkoutPolicyDocs,
      fromRoute: routes.checkoutPolicyDocs,
    },
  };

  const privacyPolicyUrl = 'https://www.axahealth.co.uk/privacy-policy/';
  const axaUKGroupUrl = 'https://www.axa.co.uk/privacy-policy/marketing/';

  function Submitter() {
    const { submitForm } = useFormikContext<unknown>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.quoteSummary, navigateOptions);
  });

  if (loading) {
    return (
      <>
        <Header />
        <Main maxWidth="xl">
          <Loader title="Just a moment." subtitle="We are just setting your preferences." />
        </Main>
      </>
    );
  }

  return (
    <>
      <Header />
      <Main>
        <TitleWrapper>
          <PageIntroduction
            title="Brilliant, glad you’ve decided to join us. Just a few last things to set everything up."
            titleTestId="title"
            includeWrapper={false}
          />
        </TitleWrapper>
        <MainWrapper>
          <ContentWrapper>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                setLoading(true);
                dispatch(updateMarketingConsents(values.marketingConsents));
                dispatch(updateContactPreferences(values.contactPreferences));
                dispatch(updateCommunicationNeeds(values.communicationNeeds));
                dispatch(updateCommunicationPreference(values.communicationPreference ? 1 : 0));

                updateConsent({
                  quoteNumber,
                  quoteKey,
                  marketingConsents: values.marketingConsents,
                  contactPreferences: values.contactPreferences,
                  communicationNeeds: values.communicationNeeds,
                  communicationPreference: values.communicationPreference ? 1 : 0,
                })
                  .then(() => {
                    setLoading(false);
                    history(routes.paymentDetails, navigateOptions);
                  })
                  .catch((error) => {
                    setLoading(false);
                    HandleError(error, dispatch);
                    history(routes.unhappyPath, unhappyPathOptions);
                  });
              }}
            >
              {({ values }) => (
                <Form>
                  <GenericCard
                    title="How would you like to receive your plan information?"
                    testId="membership-documents"
                    description={
                      <>
                        <Text sx={{ mb: '8px' }}>Your membership information includes details on:</Text>
                        <Text>
                          <BulletPoint>&bull;</BulletPoint> your cover and how to use your plan.
                        </Text>
                        <Text>
                          <BulletPoint>&bull;</BulletPoint> your claims.
                        </Text>
                        <Text sx={{ mb: '8px' }}>
                          <BulletPoint>&bull;</BulletPoint> renewal details.
                        </Text>
                        <Text semibold mb={2}>
                          Your membership information and your important documents will be stored in your online account
                          which you can access any time.
                        </Text>
                        <Text>
                          You’ll be able to access your membership information and important documents at any time on
                          your online account.
                        </Text>
                      </>
                    }
                    footerText={OnlineOrPostBlock({
                      communicationPreference: values.communicationPreference,
                      email: initialContacts?.email,
                      addressLine1: initialContacts?.addressLine1,
                      addressLine2: initialContacts?.addressLine2,
                      city: initialContacts?.city,
                      postcode: initialContacts?.postcode,
                    })}
                    gutter
                  >
                    <RadioContainer aria-label="radio-group-answers">
                      <RadioBlockWrapper data-testid="radioButton-All-online">
                        <FormikRadioBlock
                          ariaLabel="All online"
                          value={0}
                          name="communicationPreference"
                          variant={RadioVariants.brick}
                        >
                          <Text semibold>All online</Text>
                        </FormikRadioBlock>
                      </RadioBlockWrapper>
                      <RadioBlockWrapper data-testid="radioButton-Extra-copy-in-the-post">
                        <FormikRadioBlock
                          ariaLabel="Online and by post"
                          value={1}
                          name="communicationPreference"
                          variant={RadioVariants.brick}
                        >
                          <Text semibold>Online and by post</Text>
                        </FormikRadioBlock>
                      </RadioBlockWrapper>
                    </RadioContainer>
                  </GenericCard>
                  <GenericCard
                    title="How would you prefer us to contact you?"
                    testId="preferred-contact-method"
                    description={
                      <>
                        <Text mb={1}>At times, we may need to get in touch with you.</Text>
                        <Text>
                          Let us know how you’d prefer us to contact you in case we do. You can select as many options
                          as you like.
                        </Text>
                      </>
                    }
                    gutter
                  >
                    <CheckboxContainer>
                      <CheckoutCheckbox
                        name="contactPreferences.phoneCall"
                        displayName="Phone call"
                        value={values.contactPreferences.phoneCall}
                        testId="info-products-services-preferredPhoneCalls"
                      />
                      <CheckoutCheckbox
                        name="contactPreferences.email"
                        displayName="Email"
                        value={values.contactPreferences.email}
                        testId="info-products-services-preferredEmail"
                      />
                      <CheckoutCheckbox
                        name="contactPreferences.post"
                        displayName="Post"
                        value={values.contactPreferences.post}
                        testId="info-products-services-preferredPost"
                      />
                    </CheckboxContainer>
                  </GenericCard>
                  <GenericCard
                    title="How would you like information about other products and services?"
                    testId="info-products-services"
                    description="Please choose how you’d like to receive updates from AXA about other products, services, promotions, special offers and news:"
                    footerText={
                      <Text>
                        By agreeing, you are giving&nbsp;
                        <ExternalStyledLink $inline target="_blank" rel="noreferrer" href={axaUKGroupUrl}>
                          AXA UK companies
                        </ExternalStyledLink>
                        , that is AXA Insurance UK Plc, AXA PPP healthcare Limited and AXA PPP healthcare Group
                        companies permission to use the information supplied to contact you with relevant information.
                        You can withdraw your consent whenever you want by contacting us. You can view our&nbsp;
                        <ExternalStyledLink $inline href={privacyPolicyUrl} target="_blank" rel="noreferrer">
                          privacy policy
                        </ExternalStyledLink>
                        &nbsp;here.
                      </Text>
                    }
                    gutter
                  >
                    <CheckboxContainer>
                      <CheckoutCheckbox
                        name="marketingConsents.email"
                        displayName="Email"
                        value={values.marketingConsents.email}
                        testId="info-products-services-marketingEmail"
                      />
                      <CheckoutCheckbox
                        name="marketingConsents.phoneCall"
                        displayName="Phone call"
                        value={values.marketingConsents.phoneCall}
                        testId="info-products-services-marketingPhoneCalls"
                      />
                      <CheckoutCheckbox
                        name="marketingConsents.post"
                        displayName="Post"
                        value={values.marketingConsents.post}
                        testId="info-products-services-marketingPost"
                      />
                      <CheckoutCheckbox
                        name="marketingConsents.textMessage"
                        displayName="Text"
                        value={values.marketingConsents.textMessage}
                        testId="info-products-services-marketingTextMessages"
                      />
                    </CheckboxContainer>
                  </GenericCard>
                  <GenericCard
                    title="Do you have any communication needs?"
                    testId="communication-needs"
                    description={
                      <>
                        <Text mb={1}>
                          We can provide communications in a range of different ways. Just choose the way you’d like to
                          hear from us from this list below and we’ll make all the arrangements.
                        </Text>
                        <Text>
                          Once set up you’ll receive communications in the way you’ve chosen until you tell us that
                          they’re no longer needed.
                        </Text>
                      </>
                    }
                    gutter
                  >
                    <CheckboxContainer>
                      <CheckoutCheckbox
                        name="communicationNeeds.documentsInBraille"
                        displayName="Documents in braille"
                        value={values.communicationNeeds.documentsInBraille}
                        testId="info-products-services-braille"
                      />
                      <CheckoutCheckbox
                        name="communicationNeeds.documentsInLargePrint"
                        displayName="Documents in large print"
                        value={values.communicationNeeds.documentsInLargePrint}
                        testId="info-products-services-largePrint"
                      />
                      <CheckoutCheckbox
                        name="communicationNeeds.audio"
                        displayName="Audio"
                        value={values.communicationNeeds.audio}
                        testId="info-products-services-audio"
                      />
                      <CheckoutCheckbox
                        name="communicationNeeds.relayUK"
                        displayName="Relay UK"
                        value={values.communicationNeeds.relayUK}
                        testId="info-products-services-relayUK"
                      />
                      <CheckoutCheckbox
                        name="communicationNeeds.signLanguageService"
                        displayName="Sign language service"
                        value={values.communicationNeeds.signLanguageService}
                        testId="info-products-services-signLanguageService"
                      />
                    </CheckboxContainer>
                  </GenericCard>
                  <Submitter />
                  <PageNavigation />
                </Form>
              )}
            </Formik>
          </ContentWrapper>
        </MainWrapper>
      </Main>
    </>
  );
}
