import { H2 } from '@digitalportal-ui/core';
import StyledAccordion from '../../molecules/StyledAccordion/StyledAccordion';
import { Heading, Wrapper, QuestionIcon, StyledText, LinkStyle } from './styles';
import FaqContent from '../../../content/faqContent.json';
import MembershipHandbook from '../../../assets/pdfs/AXA Health Plan Handbook.pdf';

const addHandbook = (answer: string): React.JSX.Element | string => {
  if (answer.includes('{{handbook_link}}')) {
    const stringParts = answer.split(/(\{\{handbook_link\}\})/);
    return (
      <>
        {stringParts.map((part) => (part === '{{handbook_link}}' ?
          <LinkStyle
            aria-label="Handbook"
            href={MembershipHandbook}
            rel="noopener"
            target="_blank"
          >
            handbook
          </LinkStyle> : part
        ))}
      </>
    );
  }
    return answer;
};

export default function FrequentlyAskedQuestions(): React.JSX.Element {
  return (
    <Wrapper>
      <Heading>
        <QuestionIcon />
        <H2 align="left" margin-bottom="29px" data-testid="faq-title">
          Frequently asked questions
        </H2>
      </Heading>
      {FaqContent.map((content) => (
        <StyledAccordion key={content.question} title={content.question} expanded={false}>
          {content.answer.map((answer) => (
            <StyledText key={answer} mb={answer.includes('\n') ? 1 : 0} semibold={answer.includes('\b')}>
              {addHandbook(answer.replace('\b', ''))}
            </StyledText>
          ))}
        </StyledAccordion>
      ))}
    </Wrapper>
  );
}
