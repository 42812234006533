import { useFormikContext, Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { colors, H4, Text, RadioVariants } from '@digitalportal-ui/core';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Box, RadioGroup } from '@mui/material';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import { PageContentContainer, RadioWrapper, Wrapper } from './styles';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import Footer from '../../templates/Footer/Footer';
import { updateHelperGuidedSelection } from '../../../redux/slices/helpers/helpers.slice';
import { updateQuoteHospitalNetwork } from '../../../redux/slices/quote/quote.slice';
import NetworkCodes from '../../../enums/networks';
import GuidedSelectionOptions from '../../../enums/guidedSelection';
import { useGetPrice } from '../../../hooks/useGetPrice';
import { defaultNetworkSelection } from '../../../lib/utils/defaultNetworkSelection';
import FormikRadioBlock from '../../molecules/FormikRadioBlock/FormikRadioBlock';

type Option = { value: number; title: string; subtitle?: string };

const GuidedOptions: Array<Option> = [
  {
    value: GuidedSelectionOptions.guided,
    title: "I'm happy for AXA Health to source a specialist for me",
    subtitle: 'This will keep your price down.',
  },
  {
    value: GuidedSelectionOptions.nonGuided,
    title: 'I want to source my own specialist',
    subtitle: 'This gives you more flexibility, but increases the cost.',
  },
];

const PageSubtitle = () => (
  <Box>
    <Box mb={1}>
      If you&apos;re happy for us to source your specialist, this will keep your price down.
    </Box>
    <Box mb={1}>
      We&apos;ll always try to source a specialist who works from a convenient location.
      This will usually be within 20 miles, depending on where you live.
      It may not be your nearest hospital.
    </Box>
    <Box mb={1}>
      If you want to use a different specialist, you&apos;ll usually need to pay towards the cost.
    </Box>
    <Box>
      This choice is for everyone on your membership.
    </Box>
  </Box>
);

const PageModalContent = () => (
  <Box>
    <Text semibold mb={1}>If you choose &apos;I&apos;m happy for AXA Health to source a specialist for me&apos;:</Text>
    <Text display="list-item" ml={4}>
      Please contact us when you need treatment. We can book the appointment for you too.
    </Text>
    <Text display="list-item" ml={4}>
      We&apos;ll source a specialist based on your GP&apos;s referral.
    </Text>
    <Text display="list-item" ml={4} mb={1}>
      We&apos;ll try to source a specialist in a convenient location. This will usually be within 20 miles (5 miles in London)
    </Text>
    <Text display="list-item" ml={4}>
      The specialist may not work at your closest local hospital, or we may not cover them at that hospital.
      For certain treatment, you may need to travel further.
    </Text>
    <Text display="list-item" mb={1} ml={4}>
      If you use the specialist we source, we&apos;ll cover their fees in full. If you want to use a different specialist,
      you&apos;ll usually need to pay towards your treatment. Sometimes you may need to pay the full cost.
      <strong style={{ fontWeight: 600 }}> Please always contact us before treatment, and we&apos;ll tell you how much you may need to pay.</strong>
    </Text>
    <Text semibold mb={1}>If you choose &apos;I want to source my own specialist&apos;:</Text>
    <Text display="list-item" ml={4}>
      Please contact us with the name of your specialist so we can check we work with them.
    </Text>
    <Text display="list-item" ml={4} mb={1}>
      We can still find you a specialist and book the appointment for you if you prefer.
    </Text>
  </Box>
);

export default function GuidedSelection(): React.JSX.Element {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { getPrice, loading } = useGetPrice();
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const { guidedSelection, providers } = useSelector((state: TStore) => state.helpersState.helpers);
  const { hospitalNetwork, hospitalNetworkConfig } = useSelector((state: TStore) => state.quoteState.quote);
  const [originalHospitalNetworkSelection] = useState(hospitalNetwork);
  const [originalGuidedSelection] = useState(guidedSelection);

  const { members } = useSelector((state: TStore) => state.quoteState.quote);

  const noConsultationOptionsForEveryMember = members.every(
    (member) => member.consultationConfig?.options?.length === 0,
  );

  const radioClickHandler = (value: GuidedSelectionOptions) => {
    let network;

    if (value === originalGuidedSelection) {
      network = originalHospitalNetworkSelection;
    } else if (value === GuidedSelectionOptions.guided) {
      network = defaultNetworkSelection(providers);
    } else {
      network = NetworkCodes.flexibleOption;
    }

    // Temporary fix for FoundationOption not currently being in the hospital network config
    if (network === NetworkCodes.foundationOption && !hospitalNetworkConfig.options.some(option => option.value === NetworkCodes.foundationOption)) {
      network = hospitalNetworkConfig.defaultValue;
    }

    dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: network }));
    dispatch(updateHelperGuidedSelection(value));
    getPrice({ selectedHospitalNetwork: network });
  };

  useCustomEventListener('pageNavigationBack', () => {
    if (noConsultationOptionsForEveryMember) {
      history(routes.entitlements, helpers.navigateOptions);
    } else {
      history(routes.consultations, helpers.navigateOptions);
    }
  });

  function Submitter() {
    const { submitForm } = useFormikContext<{ guidedSelection: number }>();

    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }
  return (
    <>
      <Header />
      <Main>
        <Wrapper>
          <Formik
            initialValues={{ guidedSelection }}
            onSubmit={() => history(routes.hospitals, helpers.navigateOptions)}
          >
            <Form>
              <PageIntroduction
                title="If you need treatment, are you happy for us to source a specialist for you?"
                subtitle={PageSubtitle()}
                modalTitle="About your options for sourcing a specialist"
                titleTestId="title"
              >
                {PageModalContent()}
              </PageIntroduction>
              <PageContentContainer>
                <RadioGroup
                  sx={{ flexWrap: 'nowrap' }}
                  aria-label="radio-group-answers"
                >
                  {GuidedOptions.map(({ value, title, subtitle }, index) => (
                    <RadioWrapper key={value} $noGutter={index + 1 === GuidedOptions.length}>
                      <FormikRadioBlock
                        value={value}
                        name="guidedSelection"
                        dataTestId={`radioButton-${value}`}
                        variant={RadioVariants.row}
                        checkedColor={colors.oceanBlue}
                        onClick={() => radioClickHandler(value)}
                        ariaLabel={title}
                      >
                        <H4 color={colors.darkGrey} bold>
                          {title}
                        </H4>
                        {subtitle && <Text color={colors.darkGrey}>{subtitle}</Text>}
                      </FormikRadioBlock>
                    </RadioWrapper>
                  ))}
                </RadioGroup>
                <PageNavigation isNextButtonDisabled={loading} />
              </PageContentContainer>
              <Submitter />
            </Form>
          </Formik>
        </Wrapper>
        <Footer isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
