import { useCallback, useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { Modal } from '@digitalportal-ui/core';
import { InfoLink } from '../../atoms/Link/Link';
import PaymentDetails from '../../../content/paymentDetails.json';

interface Props {
  sx?: SxProps<Theme>;
}

const LeadMemberModal: React.FC<Props> = ({ sx }) => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <Box sx={sx}>
      <InfoLink link={PaymentDetails.title} testId="leadMember" onClickHandler={handleOpenModal} />
      <Modal
        variant="info"
        title={PaymentDetails.title}
        open={showModal}
        onClose={handleCloseModal}
        dataTestid="lead-member-modal"
        showCloseFab
      >
        <Box>{PaymentDetails.body}</Box>
      </Modal>
    </Box>
  );
};

export default LeadMemberModal;
