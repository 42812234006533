import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Button, H1, Text } from '@digitalportal-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateHelperIsProgressShown } from '../../redux/slices/helpers';
import routes from '../../enums/routes';
import Header from '../templates/Header/Header';
import { Main } from '../templates/styles';
import { UpdatePageTitle } from '../../lib/utils/pageTitleHandler';

export default function Error404(): React.JSX.Element {
  const dispatch = useDispatch();
  const history = useNavigate();
  const handleRoute = () => {
     history(routes.triage);
  };

  UpdatePageTitle('Page not found');

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Grid container justifyContent="space-around">
          <Grid>
            <Box
              width="100%"
              fontSize="72px"
              color="#333333"
              lineHeight="82px"
              fontFamily="Publico Headline"
              margin="25px 0"
            >
              404
            </Box>
            <H1>We can’t seem to find the page you’re looking for.</H1>
            <Text variant="subtitle1">
              The link may have expired or the content been misplaced. Try again from the Homepage.
            </Text>
            <Box width="350px" margin="60px auto 0 auto">
              <Button onClick={handleRoute} color="secondary">
                Back to the Homepage
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Main>
    </>
  );
}
